@font-face {
    font-family: Moderat;
    src: url(../fonts/Moderat-Bold.ttf) format("truetype");
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
}

/* @font-face {
    font-family: Roboto;
    src: url(../fonts/Roboto-Regular.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
} */
/* 
@font-face {
    font-family: Roboto;
    src: url(../fonts/Roboto-Bold.ttf) format("truetype");
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: RobotoMedium;
    src: url(../fonts/Roboto-Medium.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
} */
